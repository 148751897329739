import CouponRepository from '../../repositories/CouponRepository'

const state = {
  coupons: [],
  coupon: {},
  error: ''
}

const getters = {
}

const actions = {
  async get({ commit }) {
    await CouponRepository.get()
      .then(resp => {
        commit('setCoupons', resp.data.data)
      })
  },
  async detail({ commit }, id){
    await CouponRepository.detail(id)
      .then(resp => {
        commit('setCoupon', resp.data.data)
      })
  },
  async put({ commit, dispatch }, payload){
    await CouponRepository.put(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  async post({ commit }, payload){
    await CouponRepository.post(payload)
      .then(() => {
        commit('setError', '')
      }).catch(error => {
        commit('setError', error.response.data.message)
      })
  },
  async delete({ commit, dispatch }, id){
    await CouponRepository.delete(id)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  setError({ commit }){
    commit('setError', '')
  },
}

const mutations = {
  setCoupons (state, data) {
    state.coupons = data
  },
  setCoupon (state, data) {
    state.coupon = data
  },
  setError(state, msg){
    state.error = msg
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
