import Repository from './Repository'

export default {
  get(payload) {
    return Repository.get('/users', payload)
  },
  post(payload) {
    return Repository.post('/shops/users', payload)
  },
  detail(id) {
    return Repository.get('/users/' + id)
  },
  put(payload) {
    return Repository.put('/users/' + payload.user_id, payload)
  },
  delete(id) {
    return Repository.delete('/users/' + id)
  },
  listShop(payload) {
    return Repository.get('/shops', payload)
  },
  listCheckin(payload) {
    return Repository.get('/checkout', payload)
  }
}

