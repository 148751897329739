import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import { isExpiredToken } from './helpers'

import Home from './views/Home'
import Login from './views/Auth/Login'
import Profile from './views/Auth/Profile'
import NotFound from './views/NotFound'
import ProductForm from './views/Product/Form'
import ProductList from './views/Product/List'
import OrderForm from './views/Order/Form'
import OrderList from './views/Order/List'
import UserList from './views/User/List'
import CategoryList from './views/Categories/List'
import CategoryForm from './views/Categories/Form'
import ShopList from './views/Shop/List'
import AdminUser from './views/AdminUser/List'
import CouponForm from './views/Coupon/Form'
import CouponList from './views/Coupon/List'
import BannerList from './views/Banner/List'
import BannerForm from './views/Banner/Form'
Vue.use(VueRouter)

const routes = [{
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            layout: 'member',
            auth: true
        }
    },
    {
        name: 'Login',
        path: '/login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (store.state.auth.token) {
                next('/')
            }
            next()
        },
        meta: {
            layout: 'login',
            auth: undefined
        }
    },
    {
        name: 'profile',
        path: '/profile',
        component: Profile,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'product-add',
        path: '/product/add',
        component: ProductForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'product-edit',
        path: '/product/edit/:id',
        component: ProductForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'product-list',
        path: '/products',
        component: ProductList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'order-edit',
        path: '/order/edit/:id',
        component: OrderForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'order-list',
        path: '/orders',
        component: OrderList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'user-list',
        path: '/users',
        component: UserList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'admin-user',
        path: '/admin-user',
        component: AdminUser,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'categories-list',
        path: '/categories',
        component: CategoryList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'category-add',
        path: '/category/add',
        component: CategoryForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'category-edit',
        path: '/category/edit/:id',
        component: CategoryForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'shop-list',
        path: '/shops',
        component: ShopList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'coupon-add',
        path: '/coupon/add',
        component: CouponForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'coupon-edit',
        path: '/coupon/edit/:id',
        component: CouponForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'coupon-list',
        path: '/coupons',
        component: CouponList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'banners-list',
        path: '/banners',
        component: BannerList,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'banner-add',
        path: '/banner/add',
        component: BannerForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        name: 'banner-edit',
        path: '/banner/edit/:id',
        component: BannerForm,
        meta: {
            layout: 'member',
            auth: true
        },
    },
    {
        path: '/404',
        component: NotFound,
        meta: {
            auth: undefined,
            layout: 'login'
        }
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active"
})

router.beforeEach(async(to, from, next) => {
    if (['login', 'register'].includes(to.name) && store.state.auth.token) {
        next('/')
    }
    if (to.matched.some(record => record.meta.auth)) {
        if (store.state.auth.token) {
            if (!isExpiredToken()) {
                const params = {
                    refreshtoken: store.state.auth.refreshToken
                }
                await store.dispatch('auth/refreshToken',{ params })
            }
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router