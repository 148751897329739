<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-9 d-none d-sm-block">
          <h3><b-icon icon="list" aria-hidden="true"></b-icon> Order List</h3>
        </div>
      </div>
      <hr />
      <div class="col-12 col-xl-12">
        <b-container fluid class="my-1">
          <b-row>
            <b-col class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="my-1">
              <b-form-group
                label="Per page"
                label-for="per-page-select"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Main table element -->
          <b-table
            :items="orders"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
            @filtered="onFiltered"
            :striped="true"
            :bordered="true"
            :borderless="true"
            :outlined="true"
            :hover="true"
            :fixed="true"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(status)="row">
              <div v-for="(status, index) in orderStatus" :key="index">
                <b-badge v-if="row.item.order_status_id === status.id" variant="success"> {{ status.name }}</b-badge>
              </div>
              <b-badge v-if="row.item.order_status_id === 3" variant="success"> Đã sẵn sàng và giao cho vận chuyển</b-badge>
            </template>
            <template #cell(price)="row">
              <div>{{ getPrice(row.item.price)}}</div>
            </template>
            <template #cell(delivery)="row">
              <div v-if="row.item.order.delivery_method === 1">Nothing</div>
              <div v-if="row.item.order.delivery_method === 2">Shop ship</div>
              <div v-if="row.item.order.delivery_method === 3">Third-party</div>
            </template>
            <template #cell(details)="row">
              <b-button v-if="row.item.details.length > 0" size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? 'Hide' : 'Show'}} details
              </b-button>
            </template>
            <template #cell(actions)="row">
              <b-button size="sm" class="mb-2" @click="onEdit(row.item.id)">
                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
              </b-button>
            </template>
             <template #row-details="row">
              <b-table
                :items="row.item.details"
                :fields="fieldDetails"
                head-variant="light"
                :striped="true"
                :bordered="true"
                :borderless="true"
                :outlined="true"
                :hover="true"
                :fixed="true"
              >
              <template #cell(avatar)="row">
                <img v-if="row.item.product.avatar" :src="row.item.product.avatar" alt="" width="80" height="80" />
                <img v-else
                src="../../assets/img/photos/no-image.png"
                alt=""
                width="80" height="80"
                />
              </template>
              <template #cell(shop)="row">
                <p v-for="(shop, index) in listShop" :key="index">
                  <b-badge v-if="row.item.product.shop_id === shop.id" variant="info"> {{ shop.name }}</b-badge>
                </p>
              </template>
              <template #cell(product.regular_price)="row">
                <div>{{ getPrice(row.item.product.regular_price )}}</div>
              </template>
              <template #cell(amount)="row">
                <div>{{ getPrice(row.item.amount )}}</div>
              </template>
              </b-table>
            </template>
          </b-table>
          <b-col sm="4" md="4" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </b-col>
        </b-container>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      isBusy: true,
      fields: [
        {
          key: "order.customer_name",
          label: "Name"
        },
        {
          key: "order.customer_phone",
          label: "Phone"
        },
        {
          key: "order.customer_address",
          label: "Address"
        },
        {
          key: "price",
          label: "Price"
        },
        {
          key: "status",
          label: "Status"
        },
        {
          key: "details",
          label: "Details"
        },
        { key: "actions", label: "Actions" }
      ],
      fieldDetails: [
        {
          key: "avatar",
          label: "Avatar"
        },
        {
          key: "product.name",
          label: "Name"
        },
        {
          key: "shop",
          label: "Shop"
        },
        {
          key: "product.regular_price",
          label: "Price"
        },
        {
          key: "quantity",
          label: "Quantity"
        },
         {
          key: "amount",
          label: "Amount"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      loaded: false
    }
  },
  computed: {
    ...mapState({
      orders: (state) => state.order.orders,
      listShop: (state) => state.user.listShop,
      orderStatus: (state) => state.order.orderStatus,
    })
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("order/get")
      await this.$store.dispatch("order/getStatus")
      this.totalRows = this.orders.length
      this.isBusy = false
    },
    getPrice(price) {
      return price ? price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : null
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onEdit(id) {
      this.$router.push("/order/edit/" + id)
    }
  }
}
</script>