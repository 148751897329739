import Repository from './Repository'

export default {
  get(payload) {
    return Repository.get('/shops', payload)
  },
  post(payload) {
    return Repository.post('/shops', payload)
  },
  detail(id) {
    return Repository.get('/shops/' + id)
  },
  put(payload) {
    return Repository.put('/shops/' + payload.shop_id, payload)
  },
  delete(id) {
    return Repository.delete('/shops/' + id)
  }
}

