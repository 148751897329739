import UserAdminRepository from '../../repositories/AdminUserRepository'

const state = {
  adminUsers: [],
  adminUser: {},
  error: ''
}

const getters = {
}

const actions = {
  async get({ commit }) {
    await UserAdminRepository.get()
      .then(resp => {
        commit('setUsers', resp.data.data)
      })
  },
  async put({ commit, dispatch }, payload){
    await UserAdminRepository.put(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  async post({ commit, dispatch }, payload){
    await UserAdminRepository.post(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      }).catch(error => {
        commit('setError', error.response.data.message)
      })
  },
  async delete({ commit, dispatch }, id){
    await UserAdminRepository.delete(id)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  setError({ commit }){
    commit('setError', '')
  }
}

const mutations = {
  setUsers (state, data) {
    state.adminUsers = data
  },
  setUser (state, data) {
    state.adminUser = data
  },
  setError(state, msg){
    state.error = msg
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
