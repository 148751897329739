<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block text-center">
          <h3 v-if="!$route.params.id">Add new banner</h3>
          <h3 v-else>Update banner</h3>
        </div>
      </div>
    </div>
    <hr />
    <form @submit.prevent="onSave()" method="post" v-if="!loaded">
      <div
        class="alert alert-danger alert-dismissible"
        role="alert"
        v-if="error"
      >
        <div class="alert-message">
          <strong>Hello there!</strong> {{ error }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Type</label>
        <div class="col-sm-10">
          <v-select 
            label="name"
            v-model="form.type"
            :options="types"
            :disabled="$route.params.id ? 'disabled' : null"
            >
          </v-select>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Image</label>
        <div class="col-sm-10">
          <UploadImages :max="1" @change="handleImage" :images="form.image" />
          <div v-if="submitted && $v.form.image.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.image.required"
            >
              Image is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Active</label>
        <div class="col-sm-10">
          <b-form-checkbox switch v-model="form.active" size="lg" style="margin-top: 8px;"></b-form-checkbox>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-10 ms-sm-auto">
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import UploadImages from "../../components/UploadImages"
import { mapState } from "vuex"
import { required } from "vuelidate/lib/validators"
import vSelect from "vue-select"
import 'vue-select/dist/vue-select.css'

export default {
  data() {
    return {
      form: {
        id: 0,
        type: "",
        image: [],
        active: false
      },
      loaded: true,
      submitted: false,
      types: [ 
        {
          'name': 'main'
        },
         {
          'name': 'sub'
        }
      ]
    }
  },
  components: {
    UploadImages,
    vSelect
  },
  validations: {
    form: {
      type: { required },
      image: { required }
    }
  },
  computed: {
    ...mapState({
      error: (state) => state.banner.error,
      banner: (state) => state.banner.banner
    })
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getDetail(this.$route.params.id)
    }
    this.loaded = false
  },
  methods: {
    async getDetail(id) {
      await this.$store.dispatch('banner/detail', id)
      this.form = {
        id: this.banner.id,
        type: this.banner.type,
        active: this.banner.active === 1 ? true : false,
        image: [this.banner.image]
      }
    },
    handleImage(img) {
      this.form.image = img
    },
    async onSave() {
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.loaded = true
      const params = {
        id: this.form.id,
        type: !this.$route.params.id ? this.form.type.name : this.form.type,
        active: !this.form.active ? 0 : 1,
        image: this.form.image[0]
      }
      if (!this.$route.params.id) {
        await this.$store.dispatch("banner/post", params)
      } else {
        await this.$store.dispatch("banner/put", params)
      }
      if (!this.error) {
        this.loaded = false
        this.$router.push("/banners")
      } else {
        this.loaded = false
      }
    }
  }
}
</script>