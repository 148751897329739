import Repository from './Repository'

export default {
  get(payload) {
    return Repository.get('/user-admin', payload)
  },
  post(payload) {
    return Repository.post('/user-admin', payload)
  },
  put(payload) {
    return Repository.put('/user-admin/' + payload.user_id, payload)
  },
  delete(id) {
    return Repository.delete('/user-admin/' + id)
  }
}

