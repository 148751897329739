<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-9 d-none d-sm-block">
          <h3><b-icon icon="list" aria-hidden="true"></b-icon> Categories List</h3>
        </div>
        <div class="col-3 d-none d-sm-block">
          <b-button variant="primary" class="mb-2" @click="onAdd()">
            Add New
          </b-button>
        </div>
      </div>
      <hr />
      <div class="col-12 col-xl-12">
        <b-container fluid class="my-1">
          <b-row>
            <b-col class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="my-1">
              <b-form-group
                label="Per page"
                label-for="per-page-select"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Main table element -->
          <b-table
            :items="categories"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
            @filtered="onFiltered"
            :striped="true"
            :bordered="true"
            :borderless="true"
            :outlined="true"
            :hover="true"
            :fixed="true"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(avatar)="row">
              <img v-if="row.value" :src="row.value" alt="" width="80" height="80" />
              <img v-else
              src="../../assets/img/photos/no-image.png"
              alt=""
              width="80" height="80"
              />
            </template>
            <template #cell(childrenLevel2)="row">
              <b-button v-if="row.item.children.length > 0" size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? 'Hide' : 'Show'}} category child level 2
              </b-button>
            </template>
            <template #cell(shop)="row">
              <p v-for="(shop, index) in listShop" :key="index">
                <b-badge v-if="row.item.shop_id === shop.id" variant="info"> {{ shop.name }}</b-badge>
              </p>
            </template>
            <template #cell(name)="row">
              {{ row.value }}
            </template>
            <template #cell(is_public)="row">
              <b-badge variant="danger" v-if="!row.value">Unpublic</b-badge>
              <b-badge variant="success" v-else>Public</b-badge>
            </template>

            <template #cell(actions)="row">
              <b-button size="sm" class="mb-2" @click="onEdit(row.item.id)">
                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="mb-2"
                @click="onDelete(row.item)"
              >
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </b-button>
            </template>
            <template #row-details="row">
              <b-table
                :items="row.item.children"
                :fields="fieldChildrens"
                head-variant="light"
                :striped="true"
                :bordered="true"
                :borderless="true"
                :outlined="true"
                :hover="true"
                :fixed="true"
              >
              <template #cell(avatar)="row">
                <img v-if="row.value" :src="row.value" alt="" width="80" height="80" />
                <img v-else
                src="../../assets/img/photos/no-image.png"
                alt=""
                width="80" height="80"
                />
              </template>
              <template #cell(childrenLevel3)="row">
                <b-button v-if="row.item.children && row.item.children.length > 0" size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? 'Hide' : 'Show'}} category child level 3
                </b-button>
              </template>
              <template #cell(actions)="row">
                <b-button size="sm" class="mb-2" @click="onEdit(row.item.id)">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  class="mb-2"
                  @click="onDelete(row.item)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>
              </template>
              <template #row-details="row">
                <b-table
                  :items="row.item.children"
                  :fields="fieldChildrenLevel3"
                  head-variant="light"
                  :striped="true"
                  :bordered="true"
                  :borderless="true"
                  :outlined="true"
                  :hover="true"
                  :fixed="true"
                >
                <template #cell(avatar)="row">
                  <img v-if="row.value" :src="row.value" alt="" width="80" height="80" />
                  <img v-else
                  src="../../assets/img/photos/no-image.png"
                  alt=""
                  width="80" height="80"
                  />
                </template>
                <template #cell(actions)="row">
                  <b-button size="sm" class="mb-2" @click="onEdit(row.item.id)">
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    class="mb-2"
                    @click="onDelete(row.item)"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
                </b-table>
              </template>
              </b-table>
            </template>
          </b-table>
          <b-col sm="4" md="4" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </b-col>
        </b-container>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex"
import { required } from "vuelidate/lib/validators"

export default {
  data() {
    return {
      isBusy: true,
      loaded: false,
      fields: [
        {
          key: "avatar",
          label: "Avatar"
        },
        {
          key: "name",
          label: "Name"
        },
        {
          key: "slug",
          label: "Slug"
        },
        {
          key: "childrenLevel2",
          label: "Category child"
        },
        { key: "actions", label: "Actions" }
      ],
      fieldChildrens: [
        {
          key: "avatar",
          label: "Avatar"
        },
        {
          key: "name",
          label: "Name"
        },
        {
          key: "slug",
          label: "Slug"
        },
        {
          key: "childrenLevel3",
          label: "Category child"
        },
        { key: "actions", label: "Actions" }
      ],
      fieldChildrenLevel3: [
        {
          key: "avatar",
          label: "Avatar"
        },
        {
          key: "name",
          label: "Name"
        },
        {
          key: "slug",
          label: "Slug"
        },
        { key: "actions", label: "Actions" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      category: {
        user_id: "add",
        avatar: "",
        name: ""
      },
      alertMsg: null,
      submitted: false
    }
  },
  validations: {
    category: {
      avatar: { required },
      name: { required },
      level: { required }
    }
  },
  computed: {
    ...mapState({
      categories: (state) => state.category.categories,
      error: (state) => state.category.error
    })
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("category/get")
      this.totalRows = this.categories.length
      this.isBusy = false
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onDelete(category) {
      await this.$bvModal
        .msgBoxConfirm("Do you want to delete this category?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          hideBackdrop: true,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) this.deleteCategory(category.id)
        })
        .catch(() => {
          // An error occurred
        })
    },
    async deleteCategory(id) {
      this.loaded = true
      await this.$store.dispatch("category/delete", id)
      this.loaded = false
    },
    onAdd() {
      this.$router.push("/category/add")
    },
    onEdit(id) {
      this.$router.push("/category/edit/" + id)
    }
  }
}
</script>

<style lang="css">
.input-error {
  border-color: #dc3545 !important;
}
.invalid-feedback {
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
</style>