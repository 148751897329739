<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block text-center">
          <h3 v-if="!$route.params.id">Add new coupon</h3>
          <h3 v-else>Update coupon</h3>
        </div>
      </div>
    </div>
    <hr />
    <form @submit.prevent="onSave()" method="post">
      <div
        class="alert alert-danger alert-dismissible"
        role="alert"
        v-if="error"
      >
        <div class="alert-message">
          <strong>Hello there!</strong> {{ error }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Name</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.coupon.name"
            :class="[$v.form.coupon.name.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.name.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.name.required"
            >
              Name is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Code</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.coupon.code"
            :class="[$v.form.coupon.code.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.code.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.code.required"
            >
              Code is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Quantity</label>
        <div class="col-sm-10">
          <input
            type="number"
            class="form-control"
            v-model="form.coupon.quantity"
            :class="[$v.form.coupon.quantity.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.quantity.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.quantity.required"
            >
              Quantity is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Value</label>
        <div class="col-sm-10">
          <input
            type="number"
            class="form-control"
            v-model="form.coupon.value"
            :class="[$v.form.coupon.value.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.value.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.value.required"
            >
              Value is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Percent</label>
        <div class="col-sm-10">
          <input
            type="number"
            class="form-control"
            v-model="form.coupon.percent"
            :class="[$v.form.coupon.percent.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.percent.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.percent.required"
            >
              Percent is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Coupon type</label>
        <div class="col-sm-10">
          <v-select 
            label="name"
            v-model="form.coupon.coupon_type"
            :value="form.coupon.coupon_type"
            @input="setActiveCouponType"
            :options="couponType"
            :class="[$v.form.coupon.coupon_type.$error ? 'input-error' : '']"
            >
          </v-select>
          <div v-if="submitted && $v.form.coupon.coupon_type.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.coupon_type.required"
            >
              Coupon type is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Limit type</label>
        <div class="col-sm-10">
          <v-select 
            label="name"
            v-model="form.coupon.limit_type"
            :value="form.coupon.limit_type"
            @input="setActiveLimitType"
            :options="limitType"
            :class="[$v.form.coupon.limit_type.$error ? 'input-error' : '']"
            >
          </v-select>
          <div v-if="submitted && $v.form.coupon.limit_type.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.limit_type.required"
            >
              Limit type is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Limit number</label>
        <div class="col-sm-10">
          <input
            type="number"
            class="form-control"
            v-model="form.coupon.limit_number"
            :class="[$v.form.coupon.limit_number.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.limit_number.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.limit_number.required"
            >
              Limit number is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Start date</label>
        <div class="col-sm-10">
          <input
            type="date"
            class="form-control"
            v-model="form.coupon.start_date"
            :min="date"
            :class="[$v.form.coupon.start_date.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.start_date.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.start_date.required"
            >
              Start date is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">End date</label>
        <div class="col-sm-10">
          <input
            type="date"
            class="form-control"
            v-model="form.coupon.end_date"
            :min="form.coupon.start_date"
            :class="[$v.form.coupon.end_date.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.coupon.end_date.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.coupon.end_date.required"
            >
              End date is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-10 ms-sm-auto">
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import { mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import vSelect from "vue-select"
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect
  },
  data() {
    return {
      form: {
        id: 0,
        coupon: {
          name: '',
          code: '',
          quantity: '',
          value: '',
          percent: '',
          coupon_type: '',
          limit_type: '',
          limit_number: '',
          start_date: '',
          end_date: ''
        }
      },
      loaded: true,
      submitted: false,
      date: null,
      couponType: [ 
        {
          'name': 'order'
        },
         {
          'name': 'shipping'
        }
      ],
      limitType: [ 
        {
          'name': 'event'
        },
         {
          'name': 'day'
        }
      ]
    };
  },
  validations: {
    form: {
      coupon: {
        name: { required },
        code: { required },
        quantity: { required, numeric },
        value: { required, numeric },
        percent: { required, numeric },
        coupon_type: { required },
        limit_type: { required },
        limit_number: { required, numeric },
        start_date: { required },
        end_date: { required }
      }
    },
  },
  computed: {
    ...mapState({
      error: (state) => state.coupon.error,
      coupon: (state) => state.coupon.coupon
    }),
  },
  async mounted() {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1 < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1
    const day = today.getDate() < 9 ? "0" + today.getDate() : today.getDate()
    const date = year+'-'+month+'-'+day
    this.date = date
    if (this.$route.params.id) {
      await this.getDetail(this.$route.params.id)
    }
    this.loaded = false
  },
  methods: {
    async getDetail(id) {
      await this.$store.dispatch("coupon/detail", id)
      this.form = {
        id: this.coupon.id,
        coupon: {
          name: this.coupon.name,
          code: this.coupon.code,
          quantity: this.coupon.quantity,
          value: this.coupon.value,
          percent: this.coupon.percent,
          coupon_type: this.coupon.coupon_type,
          limit_type: this.coupon.limit_type,
          limit_number: this.coupon.limit_number,
          start_date: this.coupon.start_date,
          end_date: this.coupon.end_date
        }
      }
    },
    async onSave() {
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.loaded = true
      const params = {
        id: this.form.id,
        name: this.form.coupon.name,
        code: this.form.coupon.code,
        quantity: this.form.coupon.quantity,
        value: this.form.coupon.value,
        percent: this.form.coupon.percent,
        coupon_type: this.form.coupon.coupon_type,
        limit_type: this.form.coupon.limit_type,
        limit_number: this.form.coupon.limit_number,
        start_date: this.form.coupon.start_date,
        end_date: this.form.coupon.end_date
      }
      if (!this.$route.params.id) {
        await this.$store.dispatch("coupon/post", params)
      } else {
        await this.$store.dispatch("coupon/put", params)
      }
      if (!this.error) {
        this.loaded = false
        this.$router.push("/coupons")
      } else {
        this.loaded = false
      }
    },
    setActiveCouponType(val) {
      this.form.coupon.coupon_type = val.name
    },
    setActiveLimitType(val) {
      this.form.coupon.limit_type = val.name
    }
  },
};
</script>
