<template>
  <nav id="sidebar" class="sidebar js-sidebar">
    <div class="sidebar-content js-simplebar">
      <router-link class="sidebar-brand" to="/">
        <span class="sidebar-brand-text align-middle">
          {{ userInfo.full_name }}
        </span>
      </router-link>
      <div class="sidebar-user">
        <div class="d-flex justify-content-center">
          <div class="flex-shrink-0">
            <img
              src="../assets/img/avatar.png"
              class="avatar img-fluid rounded-circle rounded me-1"
            />
          </div>
          <div class="flex-grow-1 ps-2">
            <a href="javascript:;" style="color: white">
              Hello,
              <span style="font-weight: bold; font-style: italic">{{
                userInfo.full_name
              }}</span>
              <i class="align-middle me-2 fas fa-fw fa-bullhorn"></i>
            </a>
            <br/>
            <div class="badge bg-danger">{{ userInfo.role }}</div>
          </div>
        </div>
      </div>

      <ul class="sidebar-nav">
        <li
          class="sidebar-item"
          :class="[$route.path === '/' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/">
            <i class="align-middle" data-feather="grid"></i>
            <span class="align-middle">Dashboard</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/admin-user' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/admin-user">
            <i class="align-middle" data-feather="users"></i>
            <span class="align-middle">User admin</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/users' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/users">
            <i class="align-middle" data-feather="users"></i>
            <span class="align-middle">Employees</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/shops' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/shops">
            <i class="align-middle" data-feather="shopping-bag"></i>
            <span class="align-middle">Shop</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/coupons' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/coupons">
            <i class="align-middle" data-feather="package"></i>
            <span class="align-middle">Coupons</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/banners' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/banners">
            <i class="align-middle" data-feather="image"></i>
            <span class="align-middle">Banners</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/categories' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/categories">
            <i class="align-middle" data-feather="server"></i>
            <span class="align-middle">Categories</span>
          </router-link>
        </li>
        <li
          v-if="userInfo.role === 'admin'"
          class="sidebar-item"
          :class="[$route.path === '/products' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/products">
            <i class="align-middle" data-feather="server"></i>
            <span class="align-middle">Products</span>
          </router-link>
        </li>
        <li
          class="sidebar-item"
          :class="[$route.path === '/orders' ? 'active' : null]"
        >
          <router-link class="sidebar-link" to="/orders">
            <i class="align-middle" data-feather="shopping-cart"></i>
            <span class="align-middle">Orders</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex"
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo
    })
  },
  methods: {
    onLogout() {
      this.$store.dispatch("auth/logout")
      this.$router.push("/login")
    }
  }
}
</script>

<style lang="css">
.sidebar-item a {
  color: white;
}
.child-active.active .sidebar-link {
    font-weight: 400;
    border-left: 0;
    color: #518be1;
    background: transparent;
}
</style>