import Repository from './Repository'

export default {
    get(payload) {
        return Repository.get('/orders', payload)
    },
    getStatus() {
        return Repository.get('/order-status')
    },
    detail(id) {
        return Repository.get('/orders/' + id)
    },
    put(payload) {
        return Repository.put('/orders/' + payload.id, payload)
    },
    delete(id) {
        return Repository.delete('/orders/' + id)
    },
}