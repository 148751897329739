<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block text-center">
          <h3>Order info</h3>
        </div>
      </div>
    </div>
    <hr />
    <form @submit.prevent="onSave()" method="post">
      <div
        class="alert alert-danger alert-dismissible"
        role="alert"
        v-if="error"
      >
        <div class="alert-message">
          <strong>Hello there!</strong> {{ error }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Order status</label>
        <div class="col-sm-10">
          <select
            class="form-control"
            v-model="form.order_status_id"
          >
            <option :value="status.id" v-for="(status, index) in orderStatus" :key="index">{{status.name}}</option>
          </select>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Code</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.code"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Name</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            :placeholder="form.order.customer_name"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Email</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            :placeholder="form.order.customer_email"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Adress</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_address"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Delivery suburb</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.delivery_suburb"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Delivery postcode</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.delivery_postcode"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Phone</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.customer_phone"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Order date</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.order.order_date"
            disabled
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end"
          >Delivery method</label
        >
        <div class="col-sm-10">
          <select
            class="form-control"
            v-model="form.order.delivery_method"
            disabled
          >
            <option value="1">Nothing</option>
            <option value="2">Shop ship</option>
            <option value="3">Third-party</option>
          </select>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Order item</label>
        <div class="col-sm-10">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in order.details" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ item.product.name }}</td>
                  <td>{{ getPrice(item.product.regular_price) }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ getPrice(item.amount) }}</td>
                </tr>
                <tr>
                  <th colspan="3"></th>
                  <td>Total price</td>
                  <td>{{ getPrice(order.price) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-10 ms-sm-auto">
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        id: 0,
        order: {
          customer_name: '',
          customer_email: '',
          customer_phone: '',
          customer_address: '',
          delivery_suburb: '',
          delivery_postcode: ''
        }
      },
      loaded: true,
      submitted: false,
    };
  },
  validations: {
    form: {
      order_status_id: { required },
    },
  },
  computed: {
    ...mapState({
      error: (state) => state.order.error,
      order: (state) => state.order.order,
      orderStatus: (state) => state.order.orderStatus,
    }),
  },
  async mounted() {
    await this.getDetail(this.$route.params.id);
    this.form = this.order
    this.loaded = false;
  },
  methods: {
    async getDetail(id) {
      await this.$store.dispatch("order/getStatus");
      await this.$store.dispatch("order/detail", id);
    },
    getPrice(price) {
      return price ? price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : null
    },
    async onSave() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.loaded = true;
      const params = {
        id: this.$route.params.id,
        order_status_id: this.form.order_status_id,
        code: this.form.code,
      };
      await this.$store.dispatch("order/put", params);
      if (!this.error) {
        this.loaded = false;
        this.$router.push("/orders");
      } else {
        this.loaded = false;
      }
    },
  },
};
</script>
