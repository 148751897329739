import ShopRepository from '../../repositories/ShopRepository'

const state = {
  shops: [],
  shop: {},
  error: ''
}

const getters = {
}

const actions = {
  async get({ commit }) {
    await ShopRepository.get()
      .then(resp => {
        commit('setShops', resp.data.data)
      })
  },
  async detail({ commit }, id){
    await ShopRepository.detail(id)
      .then(resp => {
        commit('setShop', resp.data.data)
      })
  },
  async put({ commit, dispatch }, payload){
    await ShopRepository.put(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  async post({ commit, dispatch }, payload){
    await ShopRepository.post(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      }).catch(error => {
        commit('setError', error.response.data.message)
      })
  },
  async delete({ commit, dispatch }, id){
    await ShopRepository.delete(id)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  setError({ commit }){
    commit('setError', '')
  },
}

const mutations = {
  setShops (state, data) {
    state.shops = data
  },
  setShop (state, data) {
    state.shop = data
  },
  setError(state, msg){
    state.error = msg
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
