<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-9 d-none d-sm-block">
          <h3><b-icon icon="list" aria-hidden="true"></b-icon> Banners List</h3>
        </div>
        <div class="col-3 d-none d-sm-block">
          <b-button variant="primary" class="mb-2" @click="onAdd()">
            Add New
          </b-button>
        </div>
      </div>
      <hr />
      <div class="col-12 col-xl-12">
        <b-container fluid class="my-1">
          <b-row>
            <b-col class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="my-1">
              <b-form-group
                label="Per page"
                label-for="per-page-select"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Main table element -->
          <b-table
            :items="banners"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
            @filtered="onFiltered"
            :striped="true"
            :bordered="true"
            :borderless="true"
            :outlined="true"
            :hover="true"
            :fixed="true"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(image)="row">
              <img v-if="row.value" :src="row.value" alt="" width="160" height="80" />
              <img v-else
              src="../../assets/img/photos/no-image.png"
              alt=""
              width="80" height="80"
              />
            </template>
            <template #cell(active)="row">
              <b-badge variant="danger" v-if="!row.value">Unactive</b-badge>
              <b-badge variant="success" v-else>Active</b-badge>
            </template>

            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="mb-2"
                @click="onEdit(row.item.id)"
              >
                <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Edit
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="mb-2"
                @click="onDelete(row.item)"
              >
                <b-icon icon="trash" aria-hidden="true"></b-icon> Delete
              </b-button>
            </template>
          </b-table>
          <b-col sm="4" md="4" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            ></b-pagination>
          </b-col>
        </b-container>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      isBusy: true,
      loaded: false,
      fields: [
        {
          key: "image",
          label: "Image"
        },
        {
          key: "type",
          label: "Type"
        },
        {
          key: "active",
          label: "Active"
        },
        { key: "actions", label: "Actions" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      alertMsg: null,
      submitted: false
    }
  },
  computed: {
    ...mapState({
      banners: (state) => state.banner.banners,
      error: (state) => state.category.error
    })
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("banner/get")
      this.totalRows = this.banners.length
      this.isBusy = false
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onDelete(banner) {
      await this.$bvModal
        .msgBoxConfirm("Do you want to delete this banner?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          hideBackdrop: true,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) this.deleteBanner(banner.id)
        })
        .catch(() => {
          // An error occurred
        })
    },
    async deleteBanner(id) {
      this.loaded = true
      await this.$store.dispatch("banner/delete", id)
      this.loaded = false
    },
    onAdd() {
      this.$router.push("/banner/add")
    },
    onEdit(id) {
      this.$router.push("/banner/edit/" + id)
    }
  }
}
</script>

<style lang="css">
.input-error {
  border-color: #dc3545 !important;
}
.invalid-feedback {
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
</style>