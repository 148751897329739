<template>
  <main class="content">
    <b-overlay :show="loaded" rounded="sm">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-9 d-none d-sm-block">
            <h3>
              <b-icon icon="person-lines-fill" aria-hidden="true"></b-icon>
              Shop List
            </h3>
          </div>
          <div class="col-3 d-none d-sm-block">
            <b-button variant="primary" class="mb-2" @click="openAddModal()">
              Add <b-icon icon="person-plus" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
        <hr />
        <div class="col-12 col-xl-12">
          <b-container fluid class="my-1">
            <b-row>
              <b-col class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col class="my-1">
                <b-form-group
                  label="Per page"
                  label-for="per-page-select"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Main table element -->
            <b-table
              :items="shops"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              @filtered="onFiltered"
              :striped="true"
              :bordered="true"
              :borderless="true"
              :outlined="true"
              :hover="true"
              :fixed="true"
            >
              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  class="mb-2"
                  @click="showEditModal(row.item)"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Edit
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  class="mb-2"
                  @click="onDelete(row.item)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon> Delete
                </b-button>
              </template>
            </b-table>
            <b-col sm="4" md="4" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
              ></b-pagination>
            </b-col>
            <b-modal
              :id="shop.shop_id"
              :title="shop.shop_id === 'add' ? 'Add shop' : 'Edit shop'"
              hide-backdrop
              centered
              content-class="shadow"
              ok-only
              hide-footer
              @hide="resetShopModal()"
            >
              <form @submit.prevent="onSave()" method="post">
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                  v-if="error"
                >
                  <div class="alert-message">
                    <strong>Hello there!</strong> {{ error }}
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-form-label col-sm-4">Name</label>
                  <div class="col-sm-8">
                    <input
                      v-model="shop.name"
                      class="form-control"
                      placeholder="Name"
                      :class="[$v.shop.name.$error ? 'input-error' : '']"
                    />
                    <div v-if="submitted && $v.shop.name.$error">
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.shop.name.required"
                      >
                        Name is Required!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-form-label col-sm-4">Phone number</label>
                  <div class="col-sm-8">
                    <input
                      v-model="shop.phone"
                      class="form-control"
                      placeholder="Phone number"
                      :class="[$v.shop.phone.$error ? 'input-error' : '']"
                    />
                    <div v-if="submitted && $v.shop.phone.$error">
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.shop.phone.required"
                      >
                        Phone number is Required!
                      </p>
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.shop.phone.numeric"
                      >
                        Phone number is invalid!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-form-label col-sm-4">Address</label>
                  <div class="col-sm-8">
                    <b-form-textarea
                      v-model="shop.address"
                      placeholder="Address"
                      rows="4"
                      :class="[$v.shop.address.$error ? 'input-error' : '']"
                    ></b-form-textarea>
                    <div v-if="submitted && $v.shop.address.$error">
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.shop.address.required"
                      >
                        Address is Required!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-form-label col-sm-4">Code</label>
                  <div class="col-sm-8">
                    <input
                      v-model="shop.code"
                      class="form-control"
                      placeholder="Code"
                      :class="[$v.shop.code.$error ? 'input-error' : '']"
                    />
                    <div v-if="submitted && $v.shop.code.$error">
                      <p
                        class="invalid-feedback"
                        style="text-left"
                        v-if="!$v.shop.code.required"
                      >
                        Code is Required!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-8 ms-sm-auto">
                    <button type="submit" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </form>
            </b-modal>
          </b-container>
        </div>
      </div>
    </b-overlay>
  </main>
</template>

<script>
import { mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  data() {
    return {
      loaded: true,
      fields: [
        {
          key: "name",
          label: "Name"
        },
        {
          key: "phone",
          label: "Phone",
          class: "text-center"
        },
        {
          key: "address",
          label: "Address"
        },
        {
          key: "code",
          label: "Code"
        },
        {
          key: "slug",
          label: "Slug"
        },
        { key: "actions", label: "Actions" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      shop: {
        shop_id: "add",
        name: "",
        phone: "",
        address: "",
        code: ""
      },
      alertMsg: null,
      submitted: false
    }
  },
  validations: {
    shop: {
      phone: { required, numeric },
      name: { required },
      address: { required },
      code: { required }
    }
  },
  computed: {
    ...mapState({
      shops: (state) => state.shop.shops,
      error: (state) => state.shop.error
    })
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("shop/get")
      this.totalRows = this.shops.length
      this.loaded = false
    },
    resetShopModal() {
      this.shop.shop_id = "add"
      this.shop.name = ""
      this.shop.phone = ""
      this.shop.address = ""
      this.shop.code = ""
      this.$v.shop.$reset()
      this.$store.dispatch("shop/setError", "")
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showEditModal(shop) {
      this.shop = {
        shop_id: shop.id,
        name: shop.name,
        phone: shop.phone,
        address: shop.address,
        code: shop.code,
      }
      setTimeout(() => this.$bvModal.show(this.shop.shop_id), 100)
    },
    async onDelete(shop) {
      await this.$bvModal
        .msgBoxConfirm("Do you want to delete this shop?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          hideBackdrop: true,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) this.deleteShop(shop.id)
        })
        .catch(() => {
          // An error occurred
        })
    },
    async deleteShop(shopId) {
      this.loaded = true
      await this.$store.dispatch("shop/delete", shopId)
      this.loaded = false
    },
    async onSave() {
      this.submitted = true
      this.$v.shop.$touch()
      if (this.$v.shop.$error) return
      this.loaded = true
      let params = {
        shop_id: this.shop.shop_id,
        phone: this.shop.phone,
        name: this.shop.name,
        address: this.shop.address,
        code: this.shop.code
      }
      if (this.shop.shop_id === "add") {
        await this.$store.dispatch("shop/post", params)
      } else {
        await this.$store.dispatch("shop/put", params)
      }
      if (!this.error) {
        this.$bvModal.hide(this.shop.shop_id)
      }
      this.loaded = false
    },
    openAddModal() {
      this.shop = {
        shop_id: "add",
        name: "",
        phone: "",
        address: "",
        code: "",
      }
      setTimeout(() => this.$bvModal.show("add"), 100)
    }
  }
}
</script>

<style lang="css">
.input-error {
  border-color: #dc3545 !important;
}
.invalid-feedback {
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
</style>