<template>
  <div class="d-table-cell align-middle">
    <loading :isLoading="loaded" />
    <div class="text-center mt-4">
      <h1 class="h2">Welcome to, Cabramarket</h1>
      <p class="lead">Sign in to your account to continue</p>
      <div class="text-center">
        <img
          src="../../assets/img/logo.png"
          alt="Charles Hall"
          class="img-fluid rounded-circle"
          width="132"
          height="132"
        />
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body">
        <div class="m-sm-4">
          <div
            class="alert alert-danger alert-dismissible"
            role="alert"
            v-if="errorLogin"
          >
            <div class="alert-message">
              <strong>Hello there!</strong> {{ errorLogin }}
            </div>
          </div>
          <div
            class="alert alert-danger alert-dismissible"
            role="alert"
            v-if="errorSendOtp"
          >
            <div class="alert-message">
              <strong>Hello there!</strong> {{ errorSendOtp }}!
            </div>
          </div>
          <form
            @submit.prevent="onSendOtp()"
            method="post"
            v-if="step === 'sendOtp'"
          >
            <div class="mb-3 text-center">
              <label class="form-label"><strong>Phone number</strong></label>
            </div>
            <div class="input-group mb-3">
              <select class="form-select custom-input-group" v-model="sendOtp.country">
                <option value="+61">+61 (AU)</option>
                <option value="+84">+84 (VN)</option>
              </select>
              <input
                type="text"
                v-model="sendOtp.phone"
                class="form-control"
                :class="[$v.sendOtp.phone.$error ? 'input-error' : '']"
                placeholder="Enter your phone number"
              />
              <div v-if="submittedSendOtp && $v.sendOtp.phone.$error">
                <p class="invalid-feedback" v-if="!$v.sendOtp.phone.required">
                  Phone is Required!
                </p>
              </div>
            </div>
            <div class="text-center mt-3">
              <button class="btn btn-lg btn-success" type="submit">
                Send OTP
              </button>
            </div>
          </form>
          <form @submit.prevent="onLogin()" method="post" v-else>
            <div class="mb-3 text-center">
              <label class="form-label"><strong>Enter OTP</strong></label>
              <p style="color: green">Check your sms for OTP</p>
              <input
                class="form-control form-control-lg"
                :class="[$v.login.otp.$error ? 'input-error' : '']"
                name="otp"
                v-model="login.otp"
                placeholder="Enter your otp"
              />
              <div v-if="submittedLogin && $v.login.otp.$error">
                <p
                  class="invalid-feedback"
                  style="text-left"
                  v-if="!$v.login.otp.required"
                >
                  OTP is Required!
                </p>
              </div>
            </div>
            <div class="text-center mt-3">
              <button class="btn btn-lg btn-success" type="submit">
                Sign in
              </button>
              <button class="btn btn-lg btn-secondary" @click="goBack()">
                Go back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import { mapState } from "vuex"

export default {
  data() {
    return {
      sendOtp: {
        phone: "",
        country: "+61"
      },
      login: {
        otp: ""
      },
      submittedSendOtp: false,
      submittedLogin: false,
      step: "sendOtp",
      loaded: false
    }
  },
  validations: {
    sendOtp: {
      phone: { required }
    },
    login: {
      otp: { required }
    }
  },
  computed: {
    ...mapState({
      errorLogin: (state) => state.auth.errorLogin,
      errorSendOtp: (state) => state.auth.errorSendOtp
    })
  },
  methods: {
    async onSendOtp() {
      this.submittedSendOtp = true
      this.$v.sendOtp.$touch()
      if (this.$v.sendOtp.$error) return
      this.loaded = true
      let params = {
        phone: this.sendOtp.phone,
        country: this.sendOtp.country
      }
      await this.$store.dispatch("auth/sendOtp", params)
      if (this.errorSendOtp === "") {
        this.$store.dispatch("auth/resetErrorLogin")
        this.step = "login"
      }
      this.loaded = false
    },
    async onLogin() {
      this.loaded = true
      this.submittedLogin = true
      let params = {
        phone: this.sendOtp.phone,
        otp: this.login.otp
      }
      this.$v.login.$touch()
      if (this.$v.login.$error) return
      await this.$store.dispatch("auth/login", params)
      if (this.errorLogin == "") {
        await this.$store.dispatch("auth/getUserInfo")
        window.location.href = "/"
      }
      this.loaded = false
    },
    goBack() {
      this.$store.dispatch("auth/resetErrorLogin")
      this.step = "sendOtp"
    }
  }
}
</script>

<style lang="css">
.input-error {
  border-color: #dc3545 !important;
}
.invalid-feedback {
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
.input-group > .custom-input-group {
  width: 25% !important;
  flex: none;
}
.input-group > input {
  width: 75% !important;
}
</style>