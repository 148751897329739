<template>
  <main class="content">
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block">
          <h3>Dashboard</h3>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    async mounted() {
      await this.$store.dispatch("user/listShop")
    }
  }
</script>